export default {
  newPasswordTitle: {
    id: 'set_password_reset_new_password',
    defaultMessage: 'New password',
  },
  newPassword: {
    id: 'set_password_reset_input_password_first',
    defaultMessage: 'Password',
  },
  passwordConfirmTitle: {
    id: 'set_password_reset_confirmation_new_password',
    defaultMessage: 'Confirm the password',
  },
  passwordConfirm: {
    id: 'set_password_reset_input_password_second',
    defaultMessage: 'Password',
  },
  submit: {
    id: 'set_password_reset_button_submit',
    defaultMessage: 'Submit',
  },
  requiredFieldError: {
    id: 'form_rules_required',
    defaultMessage: 'This field is required',
  },
  passwordValidityError: {
    id: 'form_rules_password',
    defaultMessage: 'Insert a valid password',
  },
  passwordEqualityError: {
    id: 'set_password_reset_not_equal',
    defaultMessage: "These passwords don't match",
  },
  genericError: {
    id: 'set_password_generic_error',
    defaultMessage: 'Something went wrong. Please try again.',
  },
}
