<template>
  <RevContainer>
    <div class="mb-32 flex justify-center text-center">
      <div>
        <h1 class="heading-1 my-32">
          {{ i18n(translations.title) }}
        </h1>
        <p class="body-1">
          {{ i18n(translations.text) }}
        </p>
        <SetNewPasswordForm />
      </div>
    </div>
  </RevContainer>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from '#imports'
import { onBeforeMount } from 'vue'

import { useCheckPasswordResetToken } from '@backmarket/nuxt-layer-oauth/useCheckPasswordResetToken'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevContainer } from '@ds/components/Container'

import translations from '~/scopes/auth/pages/SetNewPassword/SetNewPassword.translations'
import SetNewPasswordForm from '~/scopes/auth/pages/components/SetNewPasswordForm/SetNewPasswordForm.vue'
import { ROUTES } from '~/scopes/auth/route-names'

const i18n = useI18n()
const router = useRouter()
const route = useRoute()

const { error: errorCheckPasswordResetToken } =
  await useCheckPasswordResetToken(
    route.params.userId as string,
    route.params.token as string,
  )

onBeforeMount(async () => {
  if (errorCheckPasswordResetToken.value) {
    router.push({
      name: ROUTES.AUTH.SET_NEW_PASSWORD_EXPIRED,
    })
  }
})
</script>
