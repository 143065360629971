import type { HttpEndpoint } from '@backmarket/http-api'
import { submitPasswordResetFlow } from '@backmarket/http-api/src/api-specs-identity/user/password-reset'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

/*
 * Send form to reset password
 */
export function useResetPassword() {
  async function resetPassword(
    userId: string,
    token: string,
    newPassword: string,
    newPasswordConfirmation: string,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const endpoint: HttpEndpoint<any, any> = submitPasswordResetFlow

    const body = {
      user_id: userId,
      token,
      password: newPassword,
      password_verification: newPasswordConfirmation,
    }

    const pathParams = {}

    return $httpFetch(endpoint, {
      pathParams,
      body,
    })
  }

  return { resetPassword }
}
