import { checkPasswordResetToken } from '@backmarket/http-api/src/api-specs-identity/user/password-reset'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

/*
 * Check if the password reset token is valid
 */
export function useCheckPasswordResetToken(userId: string, token: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useHttpFetch(checkPasswordResetToken, {
    body: {
      user_id: userId,
      token,
    },
  })
}
