export default {
  title: {
    id: 'set_new_password_page_title',
    defaultMessage: 'Reset the password',
  },
  text: {
    id: 'set_new_password_page_text',
    defaultMessage:
      'At least 8 characters, including 1 uppercase letter, 1 lowercase letter and 1 digit. Because you never know.',
  },
}
