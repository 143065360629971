<template>
  <RevForm
    class="md:m-auto md:w-1/2"
    :initial-values
    :validate="validate()"
    @submit="handleSubmit"
  >
    <template #default="{ values, errors, hasError }">
      <h2 class="heading-2 my-16">
        {{ i18n(translations.newPasswordTitle) }}
      </h2>
      <RevInputText
        id="newPassword"
        v-model="values.newPassword"
        class="mx-auto mb-14 mt-24 text-left"
        :error="errors.newPassword"
        :label="i18n(translations.newPassword)"
        type="password"
      />

      <h2 class="heading-2 my-16">
        {{ i18n(translations.passwordConfirmTitle) }}
      </h2>
      <RevInputText
        id="newPasswordConfirmation"
        v-model="values.newPasswordConfirmation"
        class="mx-auto mb-32 mt-16 text-left"
        :error="errors.newPasswordConfirmation"
        :label="i18n(translations.passwordConfirm)"
        type="password"
      />
      <RevButton
        id="submitPasswordReset"
        :disabled="isSubmitDisabled(hasError)"
        full-width="always"
        :loading
        type="submit"
        variant="primary"
      >
        {{ i18n(translations.submit) }}
      </RevButton>
      <p v-if="error" class="text-static-danger-hi mt-16">
        {{ error }}
      </p>
    </template>
  </RevForm>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from '#imports'
import { ref } from 'vue'

import type { HttpApiError } from '@backmarket/http-api'
import { useResetPassword } from '@backmarket/nuxt-layer-oauth/useResetPassword'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEqual } from '@backmarket/utils/object/isEqual'
import { RevButton } from '@ds/components/Button'
import {
  FORM_VALID,
  RevForm,
  makeValidate,
  matchingRegExp,
  minLength,
  required,
} from '@ds/components/Form'
import { RevInputText } from '@ds/components/InputText'

import {
  PASSWORD_MIN_SIZE,
  VALID_PASSWORD_REGEXP,
} from '~/scopes/auth/constants'
import { ROUTES } from '~/scopes/auth/route-names'

import translations from './SetNewPasswordForm.translations'
import { type FormValues } from './SetNewPasswordForm.types'

const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const tracking = useTracking()
const { resetPassword } = useResetPassword()

const initialValues = ref({
  newPassword: '',
  newPasswordConfirmation: '',
})
const loading = ref(false)
const error = ref('')

function validate() {
  const requiredMessage = i18n(translations.requiredFieldError)
  const passwordDifferenceMessage = i18n(translations.passwordEqualityError)
  const invalidPasswordMessage = i18n(translations.passwordValidityError)

  return makeValidate<FormValues>({
    newPassword: [
      required(requiredMessage),
      minLength(PASSWORD_MIN_SIZE, invalidPasswordMessage),
      matchingRegExp(VALID_PASSWORD_REGEXP, invalidPasswordMessage),
    ],
    newPasswordConfirmation: [
      required(requiredMessage),
      function passwordEquality(newPasswordConfirmation, { newPassword }) {
        return isEqual(newPassword, newPasswordConfirmation)
          ? FORM_VALID
          : passwordDifferenceMessage
      },
    ],
  })
}

function isSubmitDisabled(hasError: boolean | undefined) {
  return hasError || loading.value
}

async function handleSubmit({
  newPassword,
  newPasswordConfirmation,
}: {
  newPassword: string
  newPasswordConfirmation: string
}) {
  loading.value = true
  error.value = ''

  try {
    await resetPassword(
      route.params.userId as string,
      route.params.token as string,
      newPassword,
      newPasswordConfirmation,
    )

    tracking.trackResetPasswordSuccess()

    router.push({
      name: ROUTES.AUTH.LOGIN,
    })
  } catch (errResetPassword) {
    const errorResetPassword = errResetPassword as HttpApiError

    if (errorResetPassword.errors && errorResetPassword.errors.length > 0) {
      error.value = errorResetPassword.errors[0].title
    } else {
      error.value = i18n(translations.genericError)
    }
  }
  loading.value = false
}
</script>
